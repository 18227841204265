/* Reset */

html, body, #root, .App {
  height: 100%;
  margin: 0;
  padding: 0;
}

/* Fonts */

@font-face {
  font-family: 'press-start';
  src: url(./assets/fonts/press-start-2p-regular.ttf);
}

/* Cursors from https://www.cursors-4u.com */

a, button {
  cursor: url(https://cur.cursors-4u.net/symbols/sym-1/sym57.cur), auto !important;
}

/* Body */

body {
  background: black !important;
}

  /* Cathode-ray tube monitor scanlines */
  body::before {
    content: " ";
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 100;
    pointer-events: none;
    background: 
      linear-gradient(rgba(0, 255, 0, 0.20) 50%, rgba(0, 0, 0, 0.25) 50%), 
      linear-gradient(90deg, rgba(255, 0, 0, 0.06), rgba(0, 255, 0, 0.02), rgba(0, 0, 255, 0.06));
    background-size: 
      100% 2px,
      1px 100%;
    box-shadow: inset 0 0 80px #000; /* Vignette */
  }

  /* Flicker */
  body::after {
    content: " ";
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    z-index: 100;
    pointer-events: none;
    background: rgba(18, 16, 16, 0.1);
    animation: flicker 0.15s infinite;
  }

/* Keyframes */

@keyframes flicker {
  0% { opacity: 0.27861; }
  5% { opacity: 0.34769; }
  10% { opacity: 0.23604; }
  15% { opacity: 0.90626; }
  20% { opacity: 0.18128; } 
  25% { opacity: 0.83891; }
  30% { opacity: 0.65583; }
  35% { opacity: 0.67807; }
  40% { opacity: 0.26559; }
  45% { opacity: 0.84693; }
  50% { opacity: 0.96019; }
  55% { opacity: 0.08594; }
  60% { opacity: 0.20313; }
  65% { opacity: 0.71988; }
  70% { opacity: 0.53455; }
  75% { opacity: 0.37288; }
  80% { opacity: 0.71428; }
  85% { opacity: 0.70419; }
  90% { opacity: 0.7003; }
  95% { opacity: 0.36108; }
  100% { opacity: 0.24387; }
}

/* Scrollbar */

::-webkit-scrollbar {
  width: 0.5vw;
  height: 1vh;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }

/* Corner */
::-webkit-scrollbar-corner {
  background: transparent;
}
